import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import axios from 'axios';

import Home from "./pages/home/Home";
// import Home2 from "./pages/home2/Home";
import DevOps from "./pages/devops/DevOps";
import CloudOps from "./pages/cloudops/CloudOps";
import DevSecOps from "./pages/devsecops/DevSecOps";
import CICD from "./pages/cicd/CICD";
import IaC from "./pages/infrastructure-as-code/IaC";
import Kubernetes from "./pages/kubernetes/Kubernetes";
import CloudMigration from "./pages/cloud-migration/CloudMigration";
import DevOpsSecurity from "./pages/devops-security/DevOpsSecurity";
import ZeroDownTime from "./pages/zero-down-time/ZeroDownTime";
import EnterpriseSolutions from "./pages/enterprise-solutions/EnterpriseSolutions";
import CloudSecurity from "./pages/cloud-security/CloudSecurity";
import CloudManagement from "./pages/cloud-management/CloudManagement";
import IfC from "./pages/infrastructure-from-code/IfC";
import ServerAdmin from "./pages/server-admin/ServerAdmin";
import Containerization from "./pages/containerization/Containerization";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";

// import Sample from "./pages/sample";

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route exact path="/" element={<Home />} />
					{/* <Route exact path="/home2" element={<Home2 />} /> */}
					<Route exact path="/devops" element={<DevOps />} />
					<Route exact path="/cloudops" element={<CloudOps />} />
					<Route exact path="/devsecops" element={<DevSecOps />} />
					<Route exact path="/continuous-integration-and-continuous-deployment" element={<CICD />} />
					<Route exact path="/infrastructure-as-code" element={<IaC />} />
					<Route exact path="/kubernetes" element={<Kubernetes />} />
					<Route exact path="/cloud-migration" element={<CloudMigration />} />
					<Route exact path="/security-implementation-in-devops" element={<DevOpsSecurity />} />
					<Route exact path="/zero-downtime-deployment" element={<ZeroDownTime />} />
					<Route exact path="/enterprise-solutions" element={<EnterpriseSolutions />} />
					<Route exact path="/cloud-security" element={<CloudSecurity />} />
					<Route exact path="/cloud-management" element={<CloudManagement />} />
					<Route exact path="/infrastructure-from-code" element={<IfC />} />
					<Route exact path="/server-administration" element={<ServerAdmin />} />
					<Route exact path="/containerization" element={<Containerization />} />
					<Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

					{/* <Route exact path="/sample" element={<Sample />} /> */}
				</Routes>
			</Router>
		</div>
	);
}

export default App;
